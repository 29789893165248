import * as authApi from "api/auth.api";
import { useNavigate } from "react-router-dom";
import { useMst } from "stores/RootStore";

const Logout = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useMst();

  authApi
    .logout()
    .then(() => {
      navigate("/");
    })
    .catch((error) => {
      console.log(error);
      if (error?.response?.status === 401) {
        navigate("/");
      }
    })
    .finally(() => {
      user.logout();
    });

  return <></>;
};

export default Logout;
