import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import validators from "utils/validators";
import { Form, Formik } from "formik";
import TextfieldWrapper from "components/FormsUI/Textfield";
import { useMst } from "stores/RootStore";

import {login} from "api/auth.api";
import Alert from "@mui/material/Alert";
import { Avatar, Divider, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import { AUTH_TYPE } from "./auth.type";
import LoadingButtonWrapper from "components/FormsUI/Button/LoadingButton";
//! ------------------------

const INITIAL_FORM_STATE = {
  phone: "",
  password: "",
};
const FORM_VALIDATION = Yup.object().shape({
  phone: validators.phoneValidator.required("נא להכניס טלפון!"),
  password: Yup.string().required("שדה נדרש"),
});

type LoginProps = {
  phone: string;
  password: string;
};
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
const Login: React.FC = () => {
  const { user } = useMst();

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let location = useLocation();
  let from = "/"; //location.state?.from?.pathname || "/";

  if (from === "auth") from = "/";

  const handleSubmit = (values: LoginProps) => {
    const { phone, password } = values;
    setLoading(true);
    setError("");
    login(phone, password)
      .then((response) => {
        if (response.status === 200) {
          user.login(response.data);
          user.afterLogin();
          if (user.isOpenLoginDialog) {
            user.closeLoginDialog();
          }
          navigate(from, { replace: true });
        } else {
          setError("קוד לא תואם ,האם יצרת סיסמה? אם כן נסה שנית!");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setError("קוד לא תואם או עדיין לא יצרת סיסמה ראשונית");
        } else {
          setError(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    // <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 4,
        marginBottom: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!user.isOpenLoginDialog && (
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
      )}

      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={handleSubmit}
      >
        <Form noValidate>
          <TextfieldWrapper
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="טלפון נייד"
            name="phone"
            autoComplete="phone"
            autoFocus
          />
          <TextfieldWrapper
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="סיסמה"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Typography
            variant="subtitle2"
            component={Link}
            color="initial"
            sx={{
              pt: 2,
              pb: 2,
              textAlign: "end",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              user.setAuthType(AUTH_TYPE.RECOVER_PASSWORD);
            }}
          >
            שכחת סיסמה? כאן תוכל לשחזר או לאפס!
          </Typography>
          <LoadingButtonWrapper
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            loading={loading}
            sx={{ mt: 3, mb: 2, fontSize: "1rem" }}
          >
            התחברות
          </LoadingButtonWrapper>
        </Form>
      </Formik>

      <Box margin={1}>{error && <Alert severity="error">{error}</Alert>}</Box>
      <Box sx={{ textAlign: "center" }}>
        <Divider variant="middle" />
        <Typography
          variant="h6"
          component={Link}
          color="primary"
          sx={{
            pt: 0,
            pb: 1,
            textAlign: "center",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            user.setAuthType(AUTH_TYPE.REGISTER);
          }}
        >
          אין לך עדיין חשבון אצלנו? לחץ כאן להרשמה ותהנה מהנחה בכל קניה!
        </Typography>
      </Box>
    </Box>
    // </Container>
  );
};

export default Login;
