import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import { useParams, useSearchParams } from "react-router-dom";
import { CURRENCY } from "utils/constant";

interface Props {}

export const DonePage = (props: Props) => {
  const [orderId, setOrderId] = useState(0);
  const { user, shop } = useMst();
  const [type, setType] = useState("none");
  const [done, setDone] = useState("");
  //const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const strOrderId = searchParams.get("orderId");
  const strType = searchParams.get("type");

  useEffect(() => {
    if (
      shop.order.checkoutStep === 2 &&
      strOrderId === null &&
      shop.order.fundingId === 2
    ) {
      setDone("bank");
    } else {
      //`it is free or cc order
      setDone("approved");
      shop.clearCart();
    }
  }, [done, shop.order.fundingId, shop.order.checkoutStep]);

  // useEffect(() => {
  //   if (!orderId) {
  //     let externalOrderId = 0;
  //     if (strOrderId) {
  //       externalOrderId = parseInt(strOrderId, 10);
  //       if(isNaN(externalOrderId)) {externalOrderId=0}
  //       }
  //   setOrderId(externalOrderId);
  // }
  //   setType(strType || "");
  //   //clearCart();
  // }, [strType, orderId]);

  return (
    <Box sx={{ mt: 3 }}>
      <Container maxWidth="sm">
        <Box>
          <Typography variant="h4" color="primary">
            {done === "approved" &&
              "תודה, הזמנתך התקבלה ואנחנו כבר מכינים ושולחים אותה אליך."}
            {done === "bank" &&
              "ההזמנה נרשמה וכל מה שנשאר זה להשלים את התשלום."}
          </Typography>
          <img
            src="/images/enjoi-sheik400.png"
            alt="thanks"
            style={{ maxWidth: "100%", maxHeight: "300px" }}
          />
        </Box>
        {done === "approved" && (
          <Box sx={{ p: 2 }}>
            <Typography variant="body1">
              בחבילה יהיו החברים שלך מ Hold On לאורח חיים בריא, הם יהיו איתך כדי
              לעזור לך ליהנות כל יום מחדש בניהול שגרת תזונה בריאה עם משקל נכון
              ובקלות. ההזמנה תגיע אליך עם שליח נחמד כבר בימים הקרובים. מספר
              ההזמנה
              <strong> {shop.order.orderId}</strong>.
            </Typography>
          </Box>
        )}
        {done === "bank" && (
          <Box sx={{ p: 2, textAlign: "start" }}>
            <Typography variant="h6" align="center" color="primary">
              איזה כייף ששמת את הבריאות שלך גבוהה בסדר העדיפות. החברים שהזמנת
              מחכים לצאת אליך ולעזור לך לנהל אורח חיים בריא ובכייף.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
              נשאר לך רק להשלים את תשלום ההזמנה.
              <br />
              סכום לתשלום ההזמנה:{" "}
              <strong>
                {shop.orderParams.orderToPay} {CURRENCY}
              </strong>
              <br />
              שלחנו אימייל עם הוראות ההמשך.
            </Typography>
            {/*//!complete the order items object
           <Typography variant="body1">
            החברים שהזמנת:<br/>
            {shop.order.items}
          </Typography> */}
            <Typography variant="h5">מספר חשבון להפקדה/העברה</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              הולד און בע"מ
              <br />
              בנק לאומי - 10
              <br />
              סניף 907 (חדרה)
              <br />
              חשבון 440600/68
            </Typography>
            <Typography variant="h5">
              לאחר ביצוע ההפקדה/העברה יש לשלוח אישור ל:
            </Typography>
            <Typography variant="body1">
              אימייל: hafkadot@holdon.co.il
              <br />
              או לווטסאפ: 052-6777255
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              חשוב לציין את שם המזמין ומספר ההזמנה.
              <br />
              מספר ההזמנה שלך: <strong>{shop.order.orderId}</strong>
            </Typography>
            <Typography variant="body1">
              שים לב, ההזמנה תשמר ל 7 ימים בלבד, לאחר מכן תבוטל אוטומטית.
              הקרדיטים, השוברים או הכוכבים שקיבלת בהזמנה זו יבוטלו גם כן
            </Typography>
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" color="primary">
            אנחנו כאן תמיד כדי לתמוך ולתת לך את המענה הכי טוב והכי טעים לניהול
            אורח חיים בריא
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
