import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import { CURRENCY } from "utils/constant";
import { postApi } from "api/http";

const ShovarRow: React.FC = () => {
  const { user, shop } = useMst();
  const [codeShovar, setCodeShovar] = useState("");
  const [message, setMessage] = useState("");

  const onChange = (e: any) => {
    if (message) setMessage("");
    e.preventDefault();
    setCodeShovar(e.target.value);
  };

  const applyShovar = () => {
    if (!codeShovar) {
      setMessage("נא להזין קוד קופון");
      return;
    }
    postApi({
      url: "shovar/apply",
      params: { code: codeShovar.trim(), cartPrice: shop.cartPrice },
    })
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.error) {
            shop.validateShovar(response.data);
          } else {
            setMessage(response.data.error);
          }
        } else {
          setMessage(`קרתה שגיאה בהפעלת שובר: ${response.status}`);
        }
      })
      .catch((error) => {
        setMessage(error?.message || "קרתה שגיאה - פנה למערכת");
      });
  };

  const cancelShovar = () => {
    shop.resetShovar();
    return;
  };

  return (
    <>
      {!user.isMarketer && (
        <Box
          sx={{
            backgroundColor: "#efefff",
            minHeight: "2rem",
            mt: 2,
            p: "10px",
            border: " 1px solid #2a3efa8f",
            position: "relative",
            textAlign: "start",
            paddingBottom: "30px",
          }}
        >
          {!shop.order.shovarUsed && (
            <Typography variant="subtitle2" color="initial" sx={{ cursor: "pointer" }}>
              יש לך שובר-קופון?
            </Typography>
          )}
          <Box sx={{ pb: 1 }}>
            {shop.order.shovarUsed === 0 && (
              <>
                <TextField
                  id="vaucher"
                  variant="standard"
                  //   label="הזן קופון"
                  placeholder="הזן קופון"
                  value={codeShovar}
                  onChange={onChange}
                  sx={{ marginInlineEnd: 2 }}
                />
                <Button variant="outlined" size="small" onClick={applyShovar}>
                  הפעל קופון
                </Button>
              </>
            )}
            {shop.order.shovarUsed > 0 && shop.order.shovarCode ? (
              <Typography variant="subtitle2" sx={{ color: "secondary.dark", display: "flex", justifyContent: "space-between" }}>
                <span>
                  קוד שובר {shop.order.shovarCode} הופעל בהצלחה
                  {"  "}
                </span>
                <Button variant="outlined" size="small" onClick={cancelShovar}>
                  בטל קופון
                </Button>
              </Typography>
            ) : (
              <>
                {message && (
                  <Typography variant="subtitle2" sx={{ color: "#e00707" }}>
                    {message}
                  </Typography>
                )}
              </>
            )}
          </Box>

          <Typography variant="subtitle2" sx={{ position: "absolute", bottom: "5px", right: "20px" }}>
            {!!shop.order.shovarUsed && (
              <span>
                {shop.order.shovarUsed.toFixed(2)} {CURRENCY} -
              </span>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default observer(ShovarRow);
