import React from "react";
import "styles/App.css";
import Layout from "components/Layout/Layout";
import { BrowserRouter } from "react-router-dom";
import MyRoutes from "Routes";
import InjectAxiosInterceptors from "api/InjectAxiosInterceptors";
const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <InjectAxiosInterceptors />;
        <Layout>
          <MyRoutes />
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;
