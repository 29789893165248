import { useEffect } from "react";
import { setupInterceptors } from "./http";

const InjectAxiosInterceptors = () => {

  //useEffect(() => {
    console.log("this effect is called once");
    setupInterceptors();
  //}, []);

  // not rendering anything
  console.log("InjectAxiosInterceptors");

  return null;
};

export default InjectAxiosInterceptors;
