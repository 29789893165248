import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useMst } from "stores/RootStore";
import { observer } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//`-----------IMPORTS------------------
import { Form, Formik } from "formik";
import * as Yup from "yup";
import validators from "utils/validators";
import TextfieldWrapper from "components/FormsUI/Textfield";
import { postApi } from "api/http";

type Props = {};

interface IContactUsForm {
  message: string;
  phone: string;
  email: string;
}

//`-----------VALIDATIONS----------
const validationSchema = Yup.object().shape({
  message: Yup.string().required("נא להכניס את תוכן הפניה"),
  phone: validators.phoneValidator.required("נא להכניס טלפון!"),
  email: validators.emailValidator.required("נא להכניס אימייל!"),
});

const ContactUs = (props: Props) => {
  const { user } = useMst();
  const [loading, setLoading] = useState(false);
  const MySwal = withReactContent(Swal);

  //`-----------ON SUBMIT----------
  const onSubmit = (values: IContactUsForm, actions: any) => {
    actions.setSubmitting(true);
    setLoading(true);

    postApi({
        url: `user/contact`,
        params: { ...values },
      })
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire("פנייתך התקבלה ותטופל בהקדם");
        } else {
          MySwal.fire("שגיאת שליחה", "אירע שגיאה צור קשר טלפון או שלח אימייל", "error");
        }
      })
      .catch((err) => {
        MySwal.fire("שגיאת שליחה", "אירע שגיאה צור קשר טלפון או שלח אימייל", "error");
      })
      .finally(() => {
        setLoading(false);
        actions.setSubmitting(false);
      });
  };

  //`-----------INITIAL VALUES----------
  const initialValues = {
    message: "",
    phone: user.phone,
    email: user.email,
  };

  return (
    <Box
      sx={{
        marginInline: "auto",
        mt: 2,
        maxWidth: "1000px",
        paddingInline: 2,
      }}
    >
      <Typography variant="h4" color="primary">
        צור קשר
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-around",
          gap: "30px",
          textAlign: "start",
          flexWrap: "no-wrap",
        }}
      >
        <Box sx={{ width: { xs: "unset", sm: "50%" } }}>
          <Box>
            <Typography variant="h6" color="initial" sx={{ mb: 2 }}>
              חברים יקרים, אנחנו אוהבים לתת שירות ולהיות חלק מאורח החיים שלכם. נשמח לעזור במהירות לכל פנייה.
            </Typography>
            {/* <Typography variant="h6" color="initial">
              ניתן ליצור אתנו קשר ב:
            </Typography> */}
            <Typography variant="body1" color="primary.main" sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <a href="https://wa.me/0522575830" target="_blank" rel="noopener noreferrer">
                <span>ניתן ליצור קשר בווטסאפ </span>
                <IconButton color="secondary" aria-label="whatsapp">
                  <WhatsAppIcon />
                </IconButton>
              </a>

              <a href="tel:048370738">לטלפן לנו ל: 04-8370738</a>
              {/* טלפון: <span>04-8370738</span> */}

              <a href="mailto:support@holdon.co.il">לשלוח לנו אימייל ל: support@holdon.co.il</a>
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Typography variant="h6" color="primary.main">
            או פשוט לרשום לנו כאן את פנייתך:
          </Typography>
          {/* //`-------------- Form elements----------------- */}
          <Formik initialValues={{ ...initialValues }} validationSchema={validationSchema} onSubmit={onSubmit}>
            <Form id="my-form" name="my-form">
              <TextfieldWrapper
                multiline
                autoFocus
                fullWidth
                name="message"
                id="message"
                label="רשום את פנייתך..."
                margin="normal"
                minRows={2}
                variant="outlined"
              />

              <TextfieldWrapper id="phone" name="phone" label="טלפון" margin="normal" size="small" fullWidth variant="outlined" />

              <TextfieldWrapper
                fullWidth
                name="email"
                id="email"
                label="אימייל"
                margin="normal"
                size="small"
                variant="outlined"
              />
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <LoadingButton type="submit" loading={loading} variant="contained">
                  שלח פניה לטיפול
                </LoadingButton>
              </Box>
            </Form>
          </Formik>
        </Box>
        <Box sx={{ width: { xs: "unset", sm: "50%" } }}>
          <img src="/images/site/group600.png" alt="grop" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
