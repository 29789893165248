import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMst } from "stores/RootStore";

import { AUTH_TYPE } from "components/Auth/auth.type";
import { Box, Button, Typography } from "@mui/material";
import { getApi } from "api/http";


type Props = {
  refId: string;
  nextStep: () => void;
  lastStep: () => void;
};

const Step1 = (props: Props) => {
  const { refId, nextStep, lastStep } = props;
  const [referrer, setReferrer] = useState("");

  const getReferrer = () => {
    if (!refId) {
      setReferrer("");
      return;
    }
    getApi({ url: `user/referrer?refId=${refId}` }).then((response) => {
      if (response.status === 200) {
        setReferrer(response.data.firstName);
      } else {
        setReferrer("");
      }
    });
  };
  useEffect(() => {
    getReferrer();
  }, []);

  return (
    <Box>
      <Box>
        <img
          src="/images/site/crowed.png"
          alt="join us"
          width="100%"
          style={{ maxWidth: "400px" }}
        />
      </Box>
      <Box>
        <Typography variant="h5" color="initial">
          <span>אלפי לקוחות כבר נהנים מאורח חיים בריא יותר.</span>
          <br />
          {referrer && <span>{referrer} כבר אתנו ועכשיו תורך!</span>}
        </Typography>
      </Box>
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h6" color="initial">
          <span>נעים מאוד, כיף שהצטרפת אלינו.</span>
        </Typography>
        <Typography variant="h6" color="initial">
          <span>נתחיל עם 3 שאלות קצרות והסבר קצרצר</span>
        </Typography>
        <Typography variant="h6" color="initial">
          <span>שיעזרו לנו להכיר אותך ולך להכיר אותנו</span>
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ fontSize: "1.5rem" }}
          onClick={nextStep}
        >
          אפשר להתחיל
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="body1"
          color="primary.main"
          onClick={lastStep}
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          כבר מכיר, מעוניין להירשם
        </Typography>
      </Box>
    </Box>
  );
};

export default Step1;
