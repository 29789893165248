import { postApi, getApi } from "./http";

export async function logout(): Promise<any> {
  return getApi({ url: "auth/logout" });
}

export async function createCode(phone: string): Promise<any> {
  return postApi({ url: `auth/CreateCode?phone=${phone}` });
}

export async function validateCode(code: string, token: string): Promise<any> {
  return postApi({ url: `auth/ValidateCode`, params: { code, token } });
}

export async function resetPassword(password: string, token: string): Promise<any> {
  return postApi({ url: `auth/ResetPassword`, params: { password, token } });
}

export async function login(phone: string, password: string): Promise<any> {
  return postApi({ url: "auth/login", params: { phone, password } });
}

export async function getSessionUser(): Promise<any> {
  return getApi({ url: "auth/current" });
}
