import { useState } from "react";
import { Box, Typography, TextField, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import validators from "utils/validators";
import * as Yup from "yup";
import { postApi } from "api/http";

function RecoverPassword() {
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPhone(event.target.value);
  };
  /*
  insert phone to recieve your password:
  response:
  *ok - we sent it to your phone and email, (we find your password)
  *not recoginzed phone - please insert the correct phone you registered to the system
  *we could'nt recover your paswword - we sent you temporary password to phone email (create random new password)

  */

  const handleSubmit = () => {
    setLoading(true);
    if (error) setError("");
    try {
      validators.phoneValidator.validateSync(phone);
    } catch (err) {
      setLoading(false);
      setError((err as Yup.ValidationError).errors[0]);
      return;
    }

    setResult("");

    postApi({ url: `auth/recover?phone=${phone}` })
      .then((response) => {
        if (response.status === 200) {
          setResult(response.data);
        } else {
          if (response.status === 204) setError(`טלפון לא קיים במערכת`);
          else setError(`respons status error: ${response.status}`);
        }
      })
      .catch((err) => {
        setError(err?.response?.data || err?.message || "שגיאת התחברות");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box>
        <Typography variant="h6" color="initial">
          לקבלת או איפוס סיסמה הכנס טלפון איתו נרשמת למערכת
        </Typography>
        <div>
          <TextField
            id="phone"
            label="טלפון"
            value={phone}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            size="small"
          />
        </div>
        <p>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            type="submit"
            //fullWidth
            onClick={handleSubmit}

            //size="small"
          >
            שלחו לי סיסמה
          </LoadingButton>
        </p>
      </Box>
      {result && (
        <Box>
          <Alert severity="success"> {result}</Alert>
        </Box>
      )}
    </>
  );
}

export default RecoverPassword;
