import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import RTL from "styles/RTL";
import { ThemeProvider } from "@mui/material/styles";
import theme from "styles/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "react-query";

import "styles/index.css";
import "overlayscrollbars/css/OverlayScrollbars.css";

import { MstProvider, rootStore } from "stores/RootStore";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <RTL>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <MstProvider value={rootStore}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </MstProvider>
      </ThemeProvider>
    </RTL>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
