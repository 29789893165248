import { types, flow, applySnapshot } from "mobx-state-tree";
import { getApi } from "api/http";

//import { types, Instance, flow, applySnapshot, getParent, cast } from "mobx-state-tree";

export const settingsStoreInitialState = {
  vpRate: 1.1,
  handleFee: 13,
  creditGain: 10,
  shippingFee: 37,
  shippingThreshold: 350,
  premiumThreshold: 800,
};

export const SettingsStore = types
  .model("SettingsStore", { ...settingsStoreInitialState })
  .views((store) => ({
    get handlFeeMultiplier() {
      return (store.handleFee + 100) / 100;
    },
    get creditGainMultiplier() {
      return store.creditGain / 100;
    },
    get multiVpRateHandleFee() {
      return (store.vpRate * (100 + store.handleFee)) / 100;
    },
    get vpToAmount() {
      return (store.vpRate * (100 + store.handleFee)) / 100;
    },
  }))
  .actions((store) => ({
    afterCreate: flow(function* afterCreate() {
      try {
        const response = yield getApi({ url: "shopConfig" });
        if (response.status !== 200) {
          console.log("applying default settings values");
          applySnapshot(store, settingsStoreInitialState);
          return;
        }
        applySnapshot(store, response.data);
      } catch (error) {
        console.log("applying default settings values");
        console.log(error);
        applySnapshot(store, settingsStoreInitialState);
      }
    }),
  }));
