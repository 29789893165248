/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const setupInterceptors = (): void => {
  axios.defaults.withCredentials = true;
  // Set up Axios interceptor to add Authorization header
  axios.interceptors.request.use(
    (config: AxiosRequestConfig = {}) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // axios.interceptors.response.use(
  //   (response) => {
  //     // success
  //     console.log("response setupInterceptors");
  //     return response;
  //   },
  //   (error) => {
  //     const status = error?.response?.status;
  //     console.log("error setupInterceptors");

  //     if (!!status && status === 401) {
  //       // here we have access of the useHistory() from current Router
  //       //`localStorage.clear()
  //       window.location.href = "/";
  //     }

  //     return Promise.reject(error);
  //   }
  // );
};

export const postApi = (options: IOptionsApi): Promise<AxiosResponse<any>> => {
  const url = options.url;
  const data = options.params ? options.params : {};
  return axios.post(`${API_URL}/${url}`, data);
};

export const getApi = (options: IOptionsApi): Promise<AxiosResponse<any>> => {
  const url = options.url;
  const config = options.params
    ? {
        params: options.params,
      }
    : {};
  return axios.get(`${API_URL}/${url}`, config);
};

