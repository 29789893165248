import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextfieldWrapper from "components/FormsUI/Textfield";
import Alert from "@mui/material/Alert";
import validators from "utils/validators";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { postApi } from "api/http";
//!----------------------------------------------------------------

const FORM_VALIDATION = Yup.object().shape({
  phone: validators.phoneCellValidator.required("נא להכניס טלפון!"),
});

type Props = {
  onCodeCreated: (phone: string) => void;
};

const CreateCode = ({ onCodeCreated }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [method, setMethod] = useState("sms");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod((event.target as HTMLInputElement).value || "sms");
  };

  type FormProps = {
    phone: string;
  };
  const handleSubmit = (values: FormProps) => {
    setLoading(true);
    if (error) setError("");
    postApi({
        url: `auth/SendCode`,
        params: { phone: values.phone, method: method },
      })
      .then((response) => {
        if (response.status === 200) {
          onCodeCreated(values.phone);
        } else {
          setError(`respons status error: ${response.status}`);
        }
      })
      .catch((err) => {
        setError(err?.response?.data || err?.message || "שגיאת התחברות");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box>
      <Box margin={1}>{error && <Alert severity="error">{error}</Alert>}</Box>

      <Formik initialValues={{ phone: "" }} validationSchema={FORM_VALIDATION} onSubmit={handleSubmit}>
        <Form noValidate>
          <Box>
            <Typography variant="body1" color="initial" fontWeight="bold">
              <span>היכנס טלפון שאיתו נרשמת למערכת</span>
            </Typography>

            <TextfieldWrapper
              id="phone"
              name="phone"
              label="טלפון לקבלת קוד אימות"
              //value={code}
              //onChange={handleCodeChange}
              margin="normal"
              size="small"
              autoFocus
              fullWidth
              sx={{
                //mt: 2,
                background: "#f3f3ff",
                //width: "100%",
                //maxWidth: "300px",
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <FormControl>
                <FormLabel id="ivr-controlled-radio-buttons-group">לקבל קוד ב SMS או באימייל</FormLabel>
                <RadioGroup
                  aria-labelledby="ivr-controlled-radio-buttons-group"
                  name="ivr-radio-buttons-group"
                  value={method}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="sms"
                    control={
                      <Radio
                      // sx={{
                      //   "& .MuiSvgIcon-root": {
                      //     fontSize: 12,
                      //   },
                      // }}
                      />
                    }
                    label="SMS"
                  />
                  <FormControlLabel
                    value="ivr"
                    control={
                      <Radio
                      // sx={{
                      //   "& .MuiSvgIcon-root": {
                      //     fontSize: 12,
                      //   },
                      // }}
                      />
                    }
                    label="הודע קולית"
                  />
                  <FormControlLabel
                    value="email"
                    control={
                      <Radio
                      // sx={{
                      //   "& .MuiSvgIcon-root": {
                      //     fontSize: 12,
                      //   },
                      // }}
                      />
                    }
                    label="אימייל"
                    // sx={{
                    //   "& .MuiFormControlLabel-label": {
                    //     fontSize: 12,
                    //   },
                    // }}
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={loading}
                  type="submit"
                  fullWidth
                  //onClick={handleResendSubmit}
                  //sx={{ maxWidth: "300px" }}
                  //size="small"
                >
                  שלחו לי קוד אימות!
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default CreateCode;
