/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  property1: "thirteen-pro-2" | "thirteen-pro-1" | "thirteen-pro-3";
  className: any;
  group: string;
  groupClassName: any;
  img: string;
  overlapGroupClassName: any;
  ellipseClassName: any;
  dscfClassName: any;
  dscf: string;
  overlapClassName: any;
  dscfClassNameOverride: any;
  dscf1: string;
}

export const Iphone = ({
  property1,
  className,
  group = "/img/group-5-3.png",
  groupClassName,
  img = "/img/group-4-3.png",
  overlapGroupClassName,
  ellipseClassName,
  dscfClassName,
  dscf = "/img/dscf0323-2.png",
  overlapClassName,
  dscfClassNameOverride,
  dscf1 = "/img/dscf0325-3.png",
}: Props): JSX.Element => {
  return (
    <div className={`iphone ${className}`}>
      <div className={`overlap ${property1}`}>
        <div className="ellipse" />
        <div className="text-wrapper">אורך חיים בריא</div>
        <div className="div"> ובהנאה</div>
        <div className="text-wrapper-2">בקלות</div>
        <img
          className="group"
          alt="Group"
          src={property1 === "thirteen-pro-3" ? "/img/group-6-1.png" : "/img/group-6-2.png"}
        />
        <img
          className="img"
          alt="Group"
          src={
            property1 === "thirteen-pro-2"
              ? "/img/group-5-2.png"
              : property1 === "thirteen-pro-3"
              ? "/img/group-5-1.png"
              : group
          }
        />
        <img
          className={`group-2 ${groupClassName}`}
          alt="Group"
          src={property1 === "thirteen-pro-1" ? img : "/img/group-4-1.png"}
        />
        <div className="overlap-group-wrapper">
          <div className={`overlap-group ${overlapGroupClassName}`}>
            <div className={`ellipse-2 ${ellipseClassName}`} />
            <img
              className={`dscf ${dscfClassName}`}
              alt="Dscf"
              src={property1 === "thirteen-pro-3" ? "/img/dscf0324-1.png" : dscf}
            />
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-2">
            <div className="ellipse-3" />
            <img
              className="dscf-2"
              alt="Dscf"
              src={
                property1 === "thirteen-pro-2"
                  ? "/img/dscf0324-2.png"
                  : property1 === "thirteen-pro-3"
                  ? "/img/dscf0325-1.png"
                  : "/img/dscf0324-3.png"
              }
            />
          </div>
        </div>
        <div className="div-wrapper">
          <div className={`overlap-3 ${overlapClassName}`}>
            <div className="ellipse-4" />
            <img
              className={`dscf-3 ${dscfClassNameOverride}`}
              alt="Dscf"
              src={
                property1 === "thirteen-pro-2"
                  ? "/img/dscf0325-2.png"
                  : property1 === "thirteen-pro-3"
                  ? "/img/dscf0323-1.png"
                  : dscf1
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Iphone.propTypes = {
  property1: PropTypes.oneOf(["thirteen-pro-2", "thirteen-pro-1", "thirteen-pro-3"]),
  group: PropTypes.string,
  img: PropTypes.string,
  dscf: PropTypes.string,
  dscf1: PropTypes.string,
};
