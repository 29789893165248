import { IconButton, InputAdornment, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Textfield from "components/FormsUI/Textfield";
import Box, { BoxProps } from "@mui/material/Box";
import { Formik, Form } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useMst } from "stores/RootStore";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import validators from "utils/validators";
import * as Yup from "yup";
import { AUTH_TYPE } from "./auth.type";
import Alert from "@mui/material/Alert";
import { observer } from "mobx-react-lite";
import LoadingButtonWrapper from "components/FormsUI/Button/LoadingButton";
import InfoModal from "./RegInfoModal";
import { postApi } from "api/http";

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required("נדרש"),
  lastName: Yup.string().required("נדרש"),
  phone: validators.phoneValidator.required("נא להכניס טלפון נייד!"),
  email: validators.emailValidator.required("נדרש"),
  password: Yup.string().required("שדה נדרש"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
};

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 0,
        mb: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        ...sx,
      }}
      {...other}
    />
  );
}

const Register: React.FC = () => {
  const [userExistAlert, setUserExistAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useMst();

  //!remove this line
  useEffect(() => {
    if(user.isAuthenticated){
      user.closeLoginDialog();
    }
  // user.setRegCredentials("0546394950", "123654789");
  // user.setAuthType(AUTH_TYPE.REG_SUCCESS);
  }, []);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (values: IRegUser) => {
    setLoading(true);
    setAlertMessage("");
    setUserExistAlert(false);
    const phone=values.phone;
    const password=values.password;
    postApi({
        url: "register",
        params: {
          ...values,
          refId: user.joinId,
          reference: user.reference,
          regAnswers: user.regAnswers,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 && response.data.status.toLowerCase() === "created") {
          user.setAuthType(AUTH_TYPE.REG_SUCCESS);
          user.setRegCredentials(phone, password);
          user.resetRegAnswers();
        } else {
          setAlertMessage("קרתה שגיאה לא צפויה פנה למערכת דרך צור קשר");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 400) {
            setAlertMessage(error.response.data.message);
            if (error.response.data.status.toLowerCase() === "phoneexist") setUserExistAlert(true);
          } else {
            setAlertMessage("קרתה שגיאה לא צפויה פנה למערכת דרך צור קשר");
          }
        }
      });
  };

  if(user.isAuthenticated) {
    return null;
  }

  return (
    <>


          <div>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{
                lineHeight: 1,
                textAlign: "center",
                mb: 2,
              }}
            >
              <span>
                נרשמים כחברים (ללא עלות), מקבלים הנחות והטבות מהקניה הראשונה ונהנים ממוצרים הכי מתקדמים והכי טעימים שיש. מוצרים
                שיהיו החברים שיסייעו לך לנהל שגרת תזונה מאוזנת ובריאה בקלות ובהנאה.
              </span>
            </Typography>

            {alertMessage && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Alert severity="error" sx={{ fontSize: "16px" }}>
                  {userExistAlert ? (
                    <div>
                      <span> הטלפון הזה כבר רשום במערכת, נסה להיכנס לאתר או בחר אפס סיסמה אם שכחת אותה </span>
                      <Link
                        sx={{ paddingBlock: 2 }}
                        onClick={() => {
                          user.setAuthType(AUTH_TYPE.LOGIN);
                        }}
                      >
                        "כניסה לאתר"
                      </Link>
                    </div>
                  ) : (
                    <p> {alertMessage} </p>
                  )}
                </Alert>
              </Box>
            )}
            <Fragment>
              <Box
                sx={{
                  marginTop: 0,
                  marginBottom: 2,
                }}
              >
                <Formik
                  initialValues={{
                    ...initialValues,
                  }}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={onSubmit}
                >
                  <Form id="my-form" name="my-form">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Item>
                        <Textfield fullWidth name="firstName" label="שם פרטי" variant="outlined" />
                      </Item>
                      <Item>
                        <Textfield fullWidth name="lastName" label="שם משפחה" variant="outlined" />
                      </Item>
                      <Item>
                        <Textfield fullWidth name="phone" label="טלפון" variant="outlined" />
                      </Item>
                      <Item>
                        <Textfield
                          fullWidth
                          sx={{ direction: "ltr", textAlign: "start" }}
                          name="email"
                          label="אימייל"
                          variant="outlined"
                        />
                      </Item>
                      <Item>
                        <Textfield
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="צור סיסמה  "
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" sx={{ marginInlineEnd: "10px" }}>
                                <IconButton
                                  aria-label="החלף את נראות הסיסמה"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Item>
                      <LoadingButtonWrapper
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        loading={loading}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        הרשמה לאתר
                      </LoadingButtonWrapper>
                    </Box>
                  </Form>
                </Formik>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography textAlign="center" variant="h6" color="primary" component={Link} onClick={toggleInfo}>
                  <InfoIcon sx={{ color: "secondary.main" }} />
                  <strong>מה מרוויחים החברים באתר HoldOn</strong>
                </Typography>
              </Box>
            </Fragment>
            <InfoModal show={showInfo} onClose={() => setShowInfo(false)} isH={user.isH} />
          </div>

          {/* {created && (
            <div>
              <Typography variant="h6" color="primary">
                נרשמת בהצלחה, נשאר לך רק לבחור סיסמה אישית
              </Typography>
              <Box>
                <CreatePassword phone="" registration />
              </Box>
            </div>
          )} */}


    </>
  );
};

export default observer(Register);
